import WorkPc from "../workPc";
import "../../styles/components/Works.css";
import SectionHeader from "../sectionHeader";

const Works = () => {
  return (
    <div className="works-container">
      <SectionHeader
        title="Progetti"
        text="Ecco alcuni progetti a cui ho lavorato, sia a livello personale che in
          ambito aziendale."
      />
      <WorkPc />
    </div>
  );
};

export default Works;
