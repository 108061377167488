import line from "./icons/line.svg";
import "../styles/components/SectionHeader.css";

interface SectionHeaderProps {
  title: string;
  text: string;
}

const SectionHeader = ({ title, text }: SectionHeaderProps) => {
  return (
    <>
      <h1 className="sectionHeader-h1">{title}</h1>
      <img width="400px" src={line} alt="line icon" />
      <p className="sectionHeader-text">{text}</p>
    </>
  );
};

export default SectionHeader;
