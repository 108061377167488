import AboutMe from "../components/sections/aboutMe";
import ContactMe from "../components/sections/contactMe";
import Hero from "../components/sections/hero";
import Skills from "../components/sections/skills";
import Works from "../components/sections/works";
export default function Home() {
  return (
    <div>
      <Hero />
      <AboutMe />
      <Skills />
      <Works />
      <ContactMe />
    </div>
  );
}
