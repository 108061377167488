import { useEffect, useRef, useState } from "react";
import "../styles/components/CarouselMonitor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

interface CarouselProps {
  images: string[];
  handleProject: (projectFromChild: number) => void;
}

function CarouselMonitor({ images, handleProject }: CarouselProps) {
  const carousel = useRef<HTMLDivElement>(null);
  const [count, setCount] = useState<number>(0);

  const incrementCarousel = (delta: number) => {
    if (!carousel.current) return;

    const width = carousel.current.offsetWidth;

    if (count + delta > images.length - 1) {
      setCount(0);
      carousel.current.scrollTo(0, 0);
      return;
    } else if (count + delta < 0) {
      //if first image go to latest
      setCount(images.length - 1);
      carousel.current.scrollTo(
        carousel.current.scrollLeft + width * images.length - 1,
        0
      );
      return;
    } else {
      //Carousel.current.scrollLeft is the number of the current x value
      //we take width of the carousel

      //at the end scrollTo(xscroll, yscroll)
      //it takes the actual position and give +500 if 1 -500 if -1

      setCount((c) => c + delta);
      handleProject(count);
      carousel.current.scrollTo(carousel.current.scrollLeft + width * delta, 0);

      return;
    }
  };

  useEffect(() => {
    handleProject(count);
  }, [count, handleProject]);

  const incrementCarouselIndicator = (delta: number) => {
    if (!carousel.current) return;

    const width = carousel.current.offsetWidth;

    setCount(delta);
    carousel.current.scrollTo(width * delta, 0);
  };

  return (
    <div className="carouselMonitor-container">
      <div
        className="carouselMonitor-btn carouselMonitor-left-btn"
        onClick={() => incrementCarousel(-1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="1x" />
      </div>
      <div
        className="carouselMonitor-btn carouselMonitor-right-btn"
        onClick={() => incrementCarousel(1)}
      >
        <FontAwesomeIcon icon={faArrowRight} size="1x" />
      </div>
      <div className="carouselMonitor" ref={carousel}>
        {images.map((img, key) => {
          return (
            <div
              className={
                key === count
                  ? "carouselMonitor-item active"
                  : "carouselMonitor-item"
              }
              key={key}
            >
              <img src={img} alt="img of carouselMonitor" />
            </div>
          );
        })}
      </div>
      <div className="carouselMonitor-indicator-container">
        {[...Array(images.length)].map((_, key) => {
          return (
            <FontAwesomeIcon
              icon={faCircle}
              className={
                key === count
                  ? "active carouselMonitor-icon"
                  : "carouselMonitor-icon"
              }
              key={key}
              onClick={() => incrementCarouselIndicator(key)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CarouselMonitor;
