import { useState } from "react";
import CodeMonitor from "./codeMonitor";
import SiteMonitor from "./siteMonitor";

const WorkPc = () => {
  const [currentProject, setCurrentProject] = useState<number>(0);

  const chooseCurrentProject = (currentProject: number) => {
    setCurrentProject(currentProject);
  };

  return (
    <div className="workPc-container">
      <SiteMonitor chooseCurrentProject={chooseCurrentProject} />
      <CodeMonitor currentProject={currentProject} />
    </div>
  );
};

export default WorkPc;
