import SkillsCard from "../skillsCard";
import { faCode, faGears } from "@fortawesome/free-solid-svg-icons";
import { faReact } from "@fortawesome/free-brands-svg-icons";
import "../../styles/components/Skills.css";
import SectionHeader from "../sectionHeader";

const Skills = () => {
  const skillsCardData = [
    {
      title: "Linguaggi di programmazione",
      list: ["JAVASCRIPT", "TYPESCRIPT", "TAILWIND", "BOOTSTRAP"],
      icon: faCode,
    },
    {
      title: "Framework e librerie",
      list: ["REACT", "REMIX", "REDUX"],
      icon: faReact,
    },
    {
      title: "Strumenti di sviluppo",
      list: ["VS CODE", "GIT", "NETLIFY", "SUPABASE"],
      icon: faGears,
    },
  ];

  const renderSkillsCard = () => {
    return skillsCardData.map((card, key) => {
      return (
        <SkillsCard
          title={card.title}
          list={card.list}
          icon={card.icon}
          key={key}
        />
      );
    });
  };

  return (
    <div className="skills-container">
      <SectionHeader
        title="Skills"
        text="Mi tengo sempre aggiornato per offrire il meglio al mio team"
      />
      <div className="skills-card-container">{renderSkillsCard()}</div>
    </div>
  );
};

export default Skills;
