import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import workExperience from "../data/workExperience.json";
import DualScreen from "../images/DualScreen.png";
import pc from "../images/pc.png";
import { useEffect, useState } from "react";
import "../styles/components/SiteMonitor.css";
import CarouselMonitor from "./CarouselMonitor";

const SiteMonitor = (params: {
  chooseCurrentProject: (index: number) => void;
}) => {
  const [currentProject, setCurrentProject] = useState<number>(0);
  const carouselImages = workExperience.map((work) => work.images[0]);

  const handleProject = (projectFromChild: number) =>
    setCurrentProject(projectFromChild);

  useEffect(() => {
    params.chooseCurrentProject(currentProject);
  }, [currentProject, params]);
  return (
    <>
      {workExperience[currentProject].link && (
        <div className="siteMonitor-link-container">
          <a
            href={workExperience[currentProject].link}
            rel="noreferrer"
            target="_blank"
          >
            Vedi sito
          </a>
          <FontAwesomeIcon
            size="xs"
            className="siteMonitor-link-container-icon"
            icon={faArrowPointer}
          />
        </div>
      )}
      <img src={pc} className="siteMonitor-singleMonitor" alt="monitor1" />
      <img
        src={DualScreen}
        className="siteMonitor-dualMonitor"
        alt="monitor2"
      />
      <CarouselMonitor
        images={carouselImages}
        handleProject={(projectFromChild: number) =>
          handleProject(projectFromChild)
        }
      />
    </>
  );
};

export default SiteMonitor;
