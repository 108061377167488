import {
  faGithub,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/Footer.css";

const Footer = () => {
  return (
    <footer>
      <p>© 2023 LorenzoPalumbo. All rights reserved.</p>
      <div className="policy-container">
        <a
          href="https://www.iubenda.com/privacy-policy/36858792"
          className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "
          title="Privacy Policy "
        >
          Privacy Policy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/36858792/cookie-policy"
          className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe "
          title="Cookie Policy "
        >
          Cookie Policy
        </a>
      </div>
      <div className="social-container">
        <a
          href="https://www.instagram.com/pacho_palumbo/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon className="icon" icon={faInstagram} />
        </a>
        <a
          href="https://www.linkedin.com/in/lorenzo-palumbo/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
        </a>
        <a href="https://github.com/Pacho499" target="_blank" rel="noreferrer">
          <FontAwesomeIcon className="icon" icon={faGithub} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
