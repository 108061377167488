import { faBackward } from "@fortawesome/free-solid-svg-icons";
import Button from "../components/button";
import error from "../images/Error404.png";
import { Link } from "react-router-dom";
import "../styles/pages/ErrorPage.css";
export default function ErrorPage() {
  return (
    <div className="errorPage-container">
      <img src={error} alt="Error" />

      <Link to="/">
        <Button text="Go back Home" icon={faBackward} />
      </Link>
    </div>
  );
}
