import json from "../images/json.png";
import workExperience from "../data/workExperience.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../styles/components/CodeMonitor.css";

const CodeMonitor = (params: { currentProject: number }) => {
  const workData = workExperience[params.currentProject];
  const state = {
    title: workData.site,
    article: workData.paragraph,
    images: workData.images,
    tecnologies: workData.language,
  };

  return (
    <div className="codeMonitor-container ">
      <div className="codeMonitor-link-container">
        <Link to={`/projects/${workData.site}`} state={state}>
          Vedi dettagli
        </Link>
        <FontAwesomeIcon
          size="xs"
          className="codeMonitor-link-container-icon"
          icon={faArrowPointer}
        />
      </div>
      <div className="codeMonitor-title">
        <img src={json} width="20px" alt="jsonIcon" />
        <p>DettagliSito.json</p>
      </div>
      <p className="curlyBracket">{"{"}</p>
      <div className="jsonBody">
        <p>
          "Nome":
          <span>" {workExperience[params.currentProject].site}",</span>
        </p>
        <p>
          "Tecnologie": <span className="squareBracket">{"["}</span>
        </p>
        <ul>
          {workExperience[params.currentProject].language.map(
            (language, index) => {
              return <li key={index}>"{language}",</li>;
            }
          )}
        </ul>
        <p className="squareBracket">{"]"}</p>
      </div>
      <p className="curlyBracket">{"}"}</p>
    </div>
  );
};

export default CodeMonitor;
