import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TypeAnimation } from "react-type-animation";
import "../styles/components/HeroText.css";

const HeroText = () => {
  return (
    <div className="heroText-container">
      <TypeAnimation cursor={false} sequence={["<h1>"]} className="code" />

      <div className="header">
        <TypeAnimation
          cursor={false}
          style={{
            whiteSpace: "pre-line",
          }}
          className="type"
          sequence={[
            500,
            `Hey\nSono Lorenzo,\nun Front-end developer `,
            (el) => el?.classList.remove("type"),
          ]}
        />
      </div>

      <TypeAnimation
        cursor={false}
        sequence={[3500, "</h1>"]}
        className="code"
      />

      <TypeAnimation
        cursor={false}
        sequence={[4000, "<p>"]}
        wrapper="p"
        className="code"
      />

      <TypeAnimation
        cursor={false}
        sequence={[
          4500,
          (el) => el?.classList.add("type"),
          "Ho scoperto la mia passione per lo sviluppo web e ho deciso di intraprendere un percorso per diventare front-end developer.",
          (el) => el?.classList.remove("type"),
        ]}
        speed={75}
        wrapper="p"
        className="heroText-text"
      />

      <TypeAnimation
        cursor={false}
        sequence={[8000, (el) => el?.classList.add("type"), "</p>"]}
        wrapper="p"
        className="code"
      />

      <div className="heroText-contactMe-container">
        <h2>Contattami</h2>
        <a href="#contactSection">
          <FontAwesomeIcon icon={faEnvelope} size="xl" />
        </a>
      </div>
    </div>
  );
};

export default HeroText;
