import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/HeroCardIcon.css";

interface HeroCardIconProps {
  icon: IconDefinition;
  text: string;
}

const HeroCardIcon = ({ icon, text }: HeroCardIconProps) => {
  return (
    <div className="hero-card-icon-container">
      <FontAwesomeIcon icon={icon} />
      <p>{text}</p>
    </div>
  );
};

export default HeroCardIcon;
