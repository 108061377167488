import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "../styles/components/SkillsCard.css";

type Props = {
  title: string;
  list: string[];
  icon: IconDefinition;
};
const SkillsCard = ({ title, list, icon }: Props) => {
  return (
    <div className="skillsCard-container">
      <FontAwesomeIcon className="skillsCard-icon" icon={icon} />
      <h4 className="skillsCard-title">{title}</h4>
      <ul>
        {list.map((language, index) => {
          return <li key={index}>{language}</li>;
        })}
      </ul>
    </div>
  );
};

export default SkillsCard;
