import { FormEvent, useState } from "react";
import Input from "../input";
import {
  faCircleCheck,
  faPaperPlane,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Spinner from "../spinner";
import "../../styles/components/ContactMe.css";
import SectionHeader from "../sectionHeader";

const ContactMe = () => {
  const [formDatas, setFormDatas] = useState({
    name: "",
    email: "",
    content: "",
    send: false,
    loading: false,
  });
  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    contentError: false,
  });
  const [privacy, setPrivacy] = useState(false);
  const [sended, setSended] = useState(false);

  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const handleChange = (e: any) => {
    setFormDatas((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const emailJSData = {
    service_id: "service_a3sydah",
    template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    user_id: process.env.REACT_APP_EMAILJS_USER_ID,
    template_params: {
      name: formDatas.name,
      email: formDatas.email,
      message: formDatas.content,
      privacy: privacy,
    },
  };

  const sendEmail = async (e: FormEvent) => {
    e.preventDefault();

    setFormDatas((prev) => ({ ...prev, loading: true }));
    if (formDatas.name.trim().length < 2) {
      setErrors((prev) => ({
        ...prev,
        nameError: true,
      }));
      setFormDatas((prev) => ({ ...prev, loading: false }));
      return;
    }

    if (emailRegex.test(formDatas.email) === false) {
      setErrors((prev) => ({
        ...prev,
        emailError: true,
      }));
      setFormDatas((prev) => ({ ...prev, loading: false }));

      return;
    }

    if (formDatas.content.trim().length < 10) {
      setErrors((prev) => ({
        ...prev,
        contentError: true,
      }));
      setFormDatas((prev) => ({ ...prev, loading: false }));

      return;
    }

    try {
      await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        emailJSData
      );
      setFormDatas((prev) => ({
        ...prev,
        name: "",
        email: "",
        content: "",
        send: true,
        loading: false,
      }));

      setErrors((prev) => ({
        ...prev,
        nameError: false,
        emailError: false,
        contentError: false,
      }));

      const paperPlane = document.getElementById("paperPlane");
      const buttonText = document.getElementById("textButton");
      const correctButton = document.getElementById("correct");

      setTimeout(() => {
        correctButton?.classList.remove("hidden");
        buttonText?.classList.add("hidden");
        paperPlane?.classList.add("hidden");
      }, 1000);
      setSended(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="contactSection" className="contactMe-container">
      <SectionHeader
        title="Contattami"
        text="Scrivimi per dar vita alle tue idee"
      />
      <form className="contactMe-form-container">
        <div className="contactMe-form-input-container">
          <Input
            text="Nome *"
            placeHolder="Il tuo nome"
            id="name"
            name="name"
            onChange={handleChange}
            value={formDatas.name}
            type="text"
            error={errors.nameError}
          />
          <Input
            name="email"
            type="email"
            value={formDatas.email}
            onChange={handleChange}
            text="Email *"
            placeHolder="La tua Email"
            id="email"
            error={errors.emailError}
          />
        </div>
        <div className="contactMe-textarea">
          <label htmlFor="email">Messaggio *</label>
          <textarea
            name="content"
            onChange={handleChange}
            id="Content"
            placeholder="Messaggio"
            value={formDatas.content}
            rows={4}
          />
          {errors.contentError && (
            <p className="error">
              Il messaggio deve contenere almeno 10 caratteri
            </p>
          )}
        </div>
        <label
          className="contactMe-container-checkboxLabel"
          htmlFor="privacyCheckBox"
        >
          <input
            className="contactMe-container-checkboxInput"
            type="checkbox"
            name="privacyCheckBox"
            id="privacyCheckBox"
            title="privacyCheckBox"
            checked={privacy}
            onChange={() => {
              setPrivacy(!privacy);
            }}
          />
          <span className="ml-4">
            Accetto la{" "}
            <a
              href="https://www.iubenda.com/privacy-policy/14563034"
              className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe underline"
              title="Privacy Policy "
            >
              Privacy Policy *
            </a>
          </span>
        </label>
        <p>I campi contrassegnati da * sono obbligatori</p>
        <div className="contactMe-sendbtn-container">
          <button
            onClick={
              formDatas.send
                ? (e) => {
                    e.preventDefault();
                  }
                : sendEmail
            }
            disabled={!privacy}
          >
            <div
              className={formDatas.loading ? "sendbtn-hidden" : "sendbtn-text"}
            >
              <p id="textButton">Contattami</p>
              <FontAwesomeIcon id="paperPlane" icon={faPaperPlane} size="xl" />
            </div>
            <FontAwesomeIcon
              id="correct"
              icon={faCircleCheck}
              size="xl"
              className={sended ? "" : "sendbtn-hidden"}
            />
            {formDatas.loading && <Spinner />}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactMe;
