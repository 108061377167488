import HeroCard from "../heroCard";
import HeroText from "../heroText";
import "../../styles/components/Hero.css";
import { useEffect, useState } from "react";

export default function Hero() {
  const [programmingLanguage, setProgrammingLanguage] = useState(0);
  const [tools, setTools] = useState(0);
  const [years, setYears] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (programmingLanguage < 4)
        setProgrammingLanguage(programmingLanguage + 1);
      if (tools < 3) setTools(tools + 1);
      if (years < 4) setYears(years + 1);
    }, 100);
  }, [programmingLanguage, tools, years]);

  return (
    <div className="hero-container">
      <h2 className="hero-header">Sviluppatore Web</h2>
      <div className="hero-view-container">
        <HeroCard />
        <div className="hero-info-container">
          <HeroText />
          {/* stats */}
          <div className="hero-stats-container">
            <div className="hero-stat-wrap">
              <h2>{programmingLanguage}</h2>
              <p>Linguaggi di programmazione</p>
            </div>
            <div className="hero-stat-wrap">
              <h2>{tools}</h2>
              <p>Framework e librerie</p>
            </div>
            <div className="hero-stat-wrap">
              <h2>{years}</h2>
              <p>Strumenti di sviluppo</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
