import "../../styles/components/AboutMe.css";

export default function AboutMe() {
  return (
    <div className="aboutMe-container">
      <h2 className="aboutMe-header">Su di me</h2>
      <article className="aboutMe-article">
        <p className="aboutMe-code">{"<p>"}</p>
        <h2>Ciao!</h2>
        <div className="aboutMe-text-container">
          <p>
            Il mio nome è Lorenzo e sono specializzato nello sviluppo web con
            liguaggi e framework come React, Remix, javascript, typescript e
            altro ancora.
          </p>
          <p>
            Mi impegno a garantire sempre un lavoro impeccabile: non mi fermo al
            codice che funziona, ma mi concentro sulla sua ottimizzazione. Per
            offrire sempre il meglio, mi tengo costantemente aggiornato
            attraverso progetti personali, corsi di formazione e l'apprendimento
            di nuove tecnologie.
          </p>
          <p>
            Quando non scrivo codice mi dedico ai miei Hobby tra cui giocare a
            calcio e del sano relax con i videogame. Sognando un giorno di
            poterne creare uno da zero.
          </p>
          <p>
            Amo affrontare nuove sfide e trovare motivazione nel lavoro
            quotidiano. Per questo motivo, cerco sempre ambienti e progetti
            stimolanti che mi permettano di potenziare costantemente le mie
            competenze da sviluppatore.
          </p>
        </div>
        <p className="aboutMe-code">{"</p>"}</p>
      </article>
    </div>
  );
}
