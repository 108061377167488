import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cv from "../data/CV.pdf";
import "../styles/components/Button.css";
import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
type buttonProps = {
  text: string;
  icon: IconDefinition;
};

export default function Button({ text, icon }: buttonProps) {
  return (
    <a download="CV Lorenzo Palumbo" href={cv} className="button">
      <p>{text}</p>
      {icon && <FontAwesomeIcon icon={icon} size="lg" />}
    </a>
  );
}
