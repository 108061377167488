import ProjectCard from "../components/projectCard";
import SectionHeader from "../components/sectionHeader";
import workExperiences from "../data/workExperience.json";
import "../styles/pages/Projects.css";
const Projects = () => {
  return (
    <div className="projects-container">
      <SectionHeader title="Progetti" text="Esplora i miei progetti" />
      <div className="card-container">
        {workExperiences.map((work, id) => {
          return (
            <ProjectCard
              images={work.images}
              title={work.site}
              shortDescription={work.shortDesc}
              article={work.paragraph}
              date={work.date}
              id={id}
              workType={work.workType}
              tecnologies={work.language}
              key={id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
