import {
  faGithub,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import "../styles/components/Header.css";
import SocialLink from "./socialLink";

export default function Header() {
  const url = window.location.pathname;
  return (
    <header className="header-container">
      <div className="logo">
        <h3>{"<C/>"}</h3>
        <h3>LorenzoPalumbo</h3>
      </div>
      <nav className="nav">
        <Link to={"/"} className={url === "/" ? "link active" : "link"}>
          <p>Home</p>
        </Link>
        <Link
          to={"/projects"}
          className={url === "/projects" ? "link active" : "link"}
        >
          <p>Progetti</p>
        </Link>
        <div className="social-link-container">
          <SocialLink
            icon={faInstagram}
            link="https://www.instagram.com/pacho_palumbo/"
            text="Instagram"
          />
          <SocialLink
            icon={faLinkedinIn}
            link="https://www.linkedin.com/in/lorenzo-palumbo/"
            text="Linkedin"
          />
          <SocialLink
            icon={faGithub}
            link="https://github.com/Pacho499"
            text="Github"
          />
        </div>
      </nav>
    </header>
  );
}
