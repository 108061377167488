import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Carousel from "../components/Carousel";
import "../styles/pages/ProjectDetail.css";

const ProjectDetail = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <>
      <Helmet>
        <title>{`${data.title} | Lorenzo developer`}</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div className="project-container">
        <h2>{data.title}</h2>
        <div className="project-tech-container">
          <span>Tecnologie:</span>
          {data.tecnologies.map((tech: string, index: number) => {
            return <p key={index}>{tech}</p>;
          })}
        </div>
        <Carousel images={data.images} btnSize={30} indicatorSize={15} />
        <div className="article-container">
          {data.article.map((para: string, index: number) => {
            return <p key={index}>{para}</p>;
          })}
        </div>
      </div>
    </>
  );
};

export default ProjectDetail;
