import "../styles/components/Input.css";

type Props = {
  id: string;
  text: string;
  placeHolder: string;
  name: string;
  value: string;
  type: string;
  onChange: (e: any) => void;
  error?: boolean;
};

const Input = ({
  id,
  text,
  placeHolder,
  name,
  onChange,
  value,
  type,
  error,
}: Props) => {
  return (
    <div className="input-container">
      <label htmlFor={id}>{text}</label>
      <input
        id={id}
        name={name}
        onChange={onChange}
        type={type}
        placeholder={placeHolder}
        value={value}
      />
      {type === "text" && error === true ? (
        <p className="error">Il nome deve contenere almeno 2 caratteri</p>
      ) : type === "email" && error === true ? (
        <p className="error">Inserire un email valida</p>
      ) : null}
    </div>
  );
};

export default Input;
