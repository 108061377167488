import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/SocialLink.css";

interface SocialLinkProps {
  link: string;
  icon: IconDefinition;
  text: String;
}

export default function SocialLink({ link, icon, text }: SocialLinkProps) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="link social-link"
    >
      <FontAwesomeIcon icon={icon} className="social-icon" />
      <p className="text">{text}</p>
    </a>
  );
}
